<template>
  <v-container>
    <v-card 
      class="ma-auto pa-  "
      :loading="loading"
    >
      <v-row 
        justify="start"
        dense
        class="ma-0 pa-0"
      >
        <v-col 
          v-for="(config, index) in modulos" 
          :key="index"
          :xl="4"
          :sm="4"
          :md="4"
          :lg="3"
          :cols="12"
          class="pa-2 "
        >
          <v-card 
            class="ma-0 pa-0"
            :loading="loading"
            :color="config.cor_background"
            @click.stop="go(config.link)"
          >
            <v-card-text :class="`${config.cor_text}--text text-center `">
              <v-row dense>
                <v-col cols="12">
                  <v-icon 
                    :size="config.icon_size">{{config.icon}}</v-icon>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                    <p width="100" class="text-wrap text-truncate">
                      {{config.titulo}}
                    </p>
                  </v-col>            
              </v-row>
            </v-card-text>
          </v-card>
        </v-col> 
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
//import api from '@/http'

export default {
  name:'Login',
  data:()=>({
    loading:false,
  }), 
  computed:{  
    ...mapGetters(['modulos']),
  },
  methods:{
    go(val){
      if (this.$router.currentRoute.name !== val) {
        this.$router.push({ name: val }).catch(() => {});
      }
    },
    /*
    async get_modulos(){
      this.loading = true
      api('modulos/listar/?local=TODOS')
      .then(response=>{
        this.configs = response.data
      }).catch(error=>{
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

      }).finally(()=>{
        this.loading = false
      })
    }*/
  },
  mounted(){
    this.$store.dispatch('modulos','modulos/listar/?local=TODOS')
  }
}
</script>
<style scoped>
</style>